




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, User, Projects, Users, Props } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Utils from '@/modules/Utils';
import LoadingDialog from '@/components/LoadingDialog.vue';
import VueApexCharts from 'vue-apexcharts';
import { cloneDeep, isEqual } from 'lodash';
import UserProfile from './UserProfile.vue';
import EditUserMode from './EditUserMode.vue';
import { getUserProfile, updateUserProfile, editUserRole, setProjectProperty, setCompanyProperty } from '@/modules/ApiUsers';
import { assignUserToProject, unassignUserFromProject, createUser, deleteUser } from '@/modules/ApiUsers';

@Component({
    components: {
        ConfirmDialog,
        LoadingDialog,
        VueApexCharts,
        UserProfile,
        EditUserMode
    }
})
export default class UserDialog extends Vue{
    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;
    @User.State('role') role;
    @User.State('isManager') isManager;
    @User.State('project') project;
    @Users.Mutation('add') addUser;
    @Projects.State('list') projects;
    @Projects.Mutation('updateRestrictedByProject') updateRestrictedByProject;
    @Props.Mutation('updateCompanyProperty') updateCompanyProperty;

    @Prop() dialog_type;
    @Prop() user;
    @Prop() project_name_by_id;
    @Prop() mobile_roles;
    @Prop() notifications_prop;

    drawer = true;
    loading = true;
    type_content = '';
    utils = Utils;
    closeDialog = false;
    backDialog = false;
    user_details = {
        id: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        role: '',
        email: '',
        multi_factor_auth: false,
        multi_factor_auth_method: 'Email',
        projects: [],
        dashboard_access: true,
        notifications: false,
        notification_severity: 'high',
        mobile: []
    };
    
    async mounted() {
        if (this.dialog_type !== 'add') {
            const user_profile = await getUserProfile(this.project.company, this.user.id);
            this.user_details = {...this.user_details, ...cloneDeep(this.user), ...user_profile};
            let user_notifications = this.notifications_prop.projects.map((project) => project.users.find((user) => user.email && user.email.email_address === this.user_details.email));
            user_notifications = user_notifications && user_notifications.filter((project_notification) => project_notification);
            if (user_notifications && user_notifications.length) {
                this.user_details.notifications = true;
                this.user_details.notification_severity = user_notifications[0].email.severity;
            }
            if (!this.user_details.multi_factor_auth) {
                this.user_details.multi_factor_auth_method = 'Email';
            }
            this.user_details.dashboard_access = !this.projects.some((project) => project.restricted.includes(this.user_details.email));
            const mobile_roles = Object.values(this.mobile_roles).flat().find((user) => user['email'] === this.user_details.email);
            if (mobile_roles) {
                this.user_details.mobile = mobile_roles['role'];
                if (this.user_details.mobile.length === this.mobile_roles_options.length - 1) {
                    this.user_details.mobile.push('all');
                }
            }else {
                this.user_details.mobile = [];
            }

            if (this.user_details.projects.length === this.projects.length) {
                this.user_details.projects.push('all');
            }else if (this.user_details.role === 'CA' || this.user_details.role === 'CM') {
                this.user_details.projects = this.projects.map((project) => project.id).concat(['all']);
            }
        }
        this.type_content = this.dialog_type;
        this.loading = false;
    }

    checkMadeChanges(){
        return !isEqual(this.user_details, this.$refs.editUserMode['user_details']);
    }

    checkClosingDialog(){
        if (this.checkMadeChanges()) {
            this.closeDialog = true;
        }else {
            this.setExitDialog();
        }
    }

    checkGoBack(){
        if (this.checkMadeChanges()) {
            this.backDialog = true;
        }else {
            this.type_content = 'profile';
        }
    }

    goToProfile(){
        this.type_content = 'profile';
        this.backDialog = false;
    }

    setExitDialog(){
        this.$emit('setUserDialogIsOpen', '', null, false);
    }

    closeDialogs(){
        this.closeDialog = false;
        this.setExitDialog();
    }

    async updateUserSettings(){
        if (this.$refs.editUserMode &&
            this.$refs.editUserMode['$refs'].user_form &&
            !(this.$refs.editUserMode['$refs'].user_form as Vue & { validate: () => boolean }).validate()
        ) {
            return;
        }

        const new_user_data = this.$refs.editUserMode['user_details'];
        let succeeded = true;

        succeeded = await this.updateProfile(new_user_data);
        if (succeeded) {
            this.user_details.first_name = new_user_data.first_name;
            this.user_details.last_name = new_user_data.last_name;
            this.user_details.phone_number = new_user_data.phone_number;
            this.user_details.multi_factor_auth = new_user_data.multi_factor_auth;
            this.user_details.multi_factor_auth_method = new_user_data.multi_factor_auth_method;
        }

        const [assign_not_succeeded, unassign_not_succeeded] = await this.updateUserRole(new_user_data);
        if (assign_not_succeeded.length || unassign_not_succeeded.length) {
            succeeded = false;
        }

        const [remove_from_mobile_succeeded, update_mobile_succeeded] = await this.updateMobileRoles(new_user_data);
        if (remove_from_mobile_succeeded.length || update_mobile_succeeded.length) {
            succeeded = false;
        }else {
            this.user_details.mobile = this.$refs.editUserMode['user_details'].mobile;
        }
        
        const notifications_succeeded = await this.updateNotificationSettings(new_user_data);
        if (notifications_succeeded) {
            this.user_details.notifications = this.$refs.editUserMode['user_details'].notifications;
            this.user_details.notification_severity = this.$refs.editUserMode['user_details'].notification_severity;
        }else {
            succeeded = false;
        }

        const [access_not_succeeded, restricted_not_succeeded] = await this.updateRestrictedProp(new_user_data);
        if (access_not_succeeded.length || restricted_not_succeeded.length) {
            succeeded = false;
        }else {
            this.user_details.dashboard_access = this.$refs.editUserMode['user_details'].dashboard_access;
        }

        if (!assign_not_succeeded.length && !unassign_not_succeeded.length) {
            this.user_details.projects = this.$refs.editUserMode['user_details'].projects;
            this.user_details.role = this.$refs.editUserMode['user_details'].role;
        }
       
        this.type_content = 'profile';

        if (succeeded) {
            this.throwNotify({
                type: 'success',
                title: this.$t('Success'),
                text: this.$t(`User data updated successfully`)
            });
        }else {
            this.throwNotify({
                type: 'error',
                title: this.$t('Error'),
                text: this.$t(`Some of the user's information is not saved`)
            });
        }
    }

    async createNewUser() {
        if (this.$refs.editUserMode &&
            this.$refs.editUserMode['$refs'].user_form &&
            !(this.$refs.editUserMode['$refs'].user_form as Vue & { validate: () => boolean }).validate()
        ) {
            return;
        }

        const new_user_data = cloneDeep(this.$refs.editUserMode['user_details']);
        const password = this.$refs.editUserMode['password'];
        let succeeded = true;
        const new_user = await createUser(this.project.company, {...new_user_data, password});
        if (!new_user || typeof new_user !== 'object' || !new_user.id) {
            this.throwNotify({
                type: 'error',
                title: this.$t('Error'),
                text: this.$t(`Couldn't create this user`)
            });
            return;
        }

        new_user_data.id = new_user.id;
        this.addUser(new_user_data);

        const [assign_not_succeeded, unassign_not_succeeded] = await this.updateUserRole(new_user_data);
        if (assign_not_succeeded.length || unassign_not_succeeded.length) {
            this.throwNotify({
                type: 'error',
                title: this.$t('Error'),
                text: this.$t(`Couldn't create this user`)
            });
            await deleteUser(this.project.company, new_user_data.email);
            return;
        }

        if (new_user_data.multi_factor_auth) {
            const profile_response = await this.updateProfile(new_user_data);
            succeeded = succeeded && profile_response;
        }
        const [remove_from_mobile_succeeded, update_mobile_succeeded] = await this.updateMobileRoles(new_user_data);
        if (remove_from_mobile_succeeded.length || update_mobile_succeeded.length) {
            succeeded = false;
        }

        const notifications_succeeded = await this.updateNotificationSettings(new_user_data);
        succeeded = succeeded && notifications_succeeded;

        const [access_not_succeeded, restricted_not_succeeded] = await this.updateRestrictedProp(new_user_data);
        if (access_not_succeeded.length || restricted_not_succeeded.length) {
            succeeded = false;
        }

        if (succeeded) {
            this.throwNotify({
                type: 'success',
                title: this.$t('Success'),
                text: this.$t(`User data updated successfully`)
            });
            
        }else {
            this.throwNotify({
                type: 'error',
                title: this.$t('Error'),
                text: this.$t(`Some of the user's information is not saved`)
            });
        }

        this.addUser(new_user_data);
        this.setExitDialog();
    }

    async updateProfile(new_user_data){
        const fields = ['first_name', 'last_name', 'phone_number', 'multi_factor_auth', 'multi_factor_auth_method'];
        let profile_data = null;
        if (fields.some((field) => this.user_details[field] !== new_user_data[field])) {
            profile_data = {};
            fields.forEach((field) => {
                if (field === 'multi_factor_auth') {
                    profile_data[field] = `${new_user_data[field]}`;
                }else {
                    profile_data[field] = new_user_data[field];
                }
            });
        }
        if (profile_data) {
            profile_data.email = new_user_data.email;
            return await updateUserProfile(this.project.company, new_user_data.id, profile_data);
        }else {
            return true;
        }
    }

    async updateUserRole(new_user_data) {
        if (this.user_details.role === new_user_data.role && isEqual(this.user_details.projects, new_user_data.projects)) return [[], []];

        if (this.user_details.role !== new_user_data.role) {
            const result = await editUserRole(this.project.company, new_user_data);
            if (!result) return [['role'], []];
        }

        const prev_admin = this.user_details.role === 'CA' || this.user_details.role === 'CM';
        const new_admin = new_user_data.role === 'CA' || new_user_data.role === 'CM';
        const prev_project_user = this.user_details.role === 'PU' || this.user_details.role === 'PR';
        const new_project_user = new_user_data.role === 'PU' || new_user_data.role === 'PR';

        if (prev_admin && new_admin || (this.dialog_type === 'add' && new_admin)) return [[], []];
        
        let assign_project_ids = [], unassign_project_ids = [];
        if (prev_admin && new_project_user) {
            assign_project_ids.push(...new_user_data.projects);
        }else if (prev_project_user && new_admin) {
            unassign_project_ids.push(...this.user_details.projects);
        }else if (!isEqual(this.user_details.projects, new_user_data.projects)) {
            new_user_data.projects.forEach((project_id) => {
                if (!this.user_details.projects.includes(project_id)) {
                    assign_project_ids.push(project_id);
                }
            });

            this.user_details.projects.forEach((project_id) => {
                if (!new_user_data.projects.includes(project_id)) {
                    unassign_project_ids.push(project_id);
                }
            });
        }

        assign_project_ids = assign_project_ids.filter((project_id) => project_id !== 'all');
        unassign_project_ids = unassign_project_ids.filter((project_id) => project_id !== 'all');

        const assign_not_succeeded = await this.assignProjectsUser(assign_project_ids, new_user_data.id);
        const unassign_not_succeeded = await this.unassignProjectsUser(unassign_project_ids, new_user_data.id);

        return [assign_not_succeeded, unassign_not_succeeded];
    }

    async assignProjectsUser(project_ids, user_id){
        const not_succeeded = [];

        await Promise.all(project_ids.map(async (project_id) => {
            const assign_response = await assignUserToProject(
                this.project.company,
                project_id,
                user_id
            );
            if (!assign_response) {
                not_succeeded.push(project_id);
            }
        }));
        return not_succeeded;
    }

    async unassignProjectsUser(project_ids, user_id){
        const not_succeeded = [];
        await Promise.all(project_ids.map(async (project_id) => {
            const unassign_response = await unassignUserFromProject(
                this.project.id,
                project_id,
                user_id
            );
            if (!unassign_response) {
                not_succeeded.push(project_id);
            }
        }));
        return not_succeeded;
    }

    async updateNotificationSettings(new_user_data){
        if ((!this.user_details.notifications && !new_user_data.notifications) ||
            (this.user_details.notifications && new_user_data.notifications && isEqual(this.user_details.projects, new_user_data.projects))
        ) {
            return true;
        }
        const notifications_prop_to_server = cloneDeep(this.notifications_prop);
        const projects_list = new_user_data.projects.filter((project_id) => project_id !== 'all');
        const prev_projects_list = this.user_details.projects.filter((project_id) => project_id !== 'all');
        const project_index_by_id = {};
        notifications_prop_to_server.projects.forEach((project, index) => project_index_by_id[project.project_id] = index);

        let projects_to_remove = [], projects_to_add = [];
        if (!this.user_details.notifications && new_user_data.notifications) {
            projects_to_add = projects_list;
        }else if (this.user_details.notifications && new_user_data.notifications && !isEqual(prev_projects_list, projects_list)) {
            projects_to_remove = prev_projects_list.filter((project_id) => !projects_list.includes(project_id));
            projects_to_add = projects_list.filter((project_id) => !prev_projects_list.includes(project_id));
        }else if (this.user_details.notifications && !new_user_data.notifications) {
            projects_to_remove = prev_projects_list;
        }

        projects_to_add.forEach((project_id) => {
            if (project_index_by_id.hasOwnProperty(project_id)) {
                const current_user = notifications_prop_to_server.projects[project_index_by_id[project_id]]
                    .users.find((user) => user.email.email_address === new_user_data.email);
                if (current_user) {
                    current_user.email.severity = new_user_data.notification_severity; 
                }else {
                    notifications_prop_to_server.projects[project_index_by_id[project_id]].users.push({
                        email: {
                            email_address: new_user_data.email,
                            severity: new_user_data.notification_severity
                        }
                    });
                }
            }else {
                const current_project = this.projects.find((project) => project.id === project_id);
                let group_id = -1;
                if (current_project) {
                    group_id = current_project.group_id;
                }
                notifications_prop_to_server.projects.push({
                    project_id,
                    group_id,
                    users: [{
                        email: {
                            email_address: new_user_data.email,
                            severity: new_user_data.notification_severity
                        }
                    }]
                });
            }
        });
        const projects_to_delete = [];
        notifications_prop_to_server.projects.forEach((project) => {
            if (projects_to_remove.includes(project.project_id)) {
                project.users = project.users.filter((user) => user.email.email_address !== new_user_data.email);
                if (!project.users.length) projects_to_delete.push(project.project_id);
            }
        });
        notifications_prop_to_server.projects = notifications_prop_to_server.projects.filter((project) => !projects_to_delete.includes(project.project_id));

        if (!isEqual(this.notifications_prop, notifications_prop_to_server)) {
            const notifications_response = await setCompanyProperty(this.project.company, 'company.notification_settings', notifications_prop_to_server);
            if (notifications_response) {
                this.$emit('updateNotificationsSettings', notifications_prop_to_server);
                this.updateCompanyProperty({property_name: 'company.notification_settings', notifications_prop_to_server});
                return true;
            }
            return false;
        }
        return true;
    }

    async updateMobileRoles(new_user_data) {
        if (isEqual(this.user_details.mobile, new_user_data.mobile) && isEqual(this.user_details.projects, new_user_data.projects) || 
            this.user_details.mobile.length === 0 && new_user_data.mobile.length === 0){
                return [[], []];
        }

        const new_projects_list = new_user_data.projects.filter((project_id) => project_id !== 'all');
        const prev_projects_list = this.user_details.projects.filter((project_id) => project_id !== 'all');
        const new_mobile_roles = new_user_data.mobile.filter((role) => role !== 'all');
        const prev_mobile_roles = this.user_details.mobile.filter((role) => role !== 'all');

        let remove_from_project_ids = [], update_project_ids = [];
        if (!prev_mobile_roles.length && new_mobile_roles.length) {
            update_project_ids = new_projects_list;
        }else if (prev_mobile_roles.length && !new_mobile_roles.length) {
            remove_from_project_ids = prev_projects_list;
        }else {
            remove_from_project_ids = prev_projects_list.filter((project_id) => !new_projects_list.includes(project_id));
            if (isEqual(prev_mobile_roles, new_mobile_roles)){
                update_project_ids = new_projects_list.filter((project_id) => !prev_projects_list.includes(project_id));
            }else {
                update_project_ids = new_projects_list;
            }
        }
        const remove_not_succeeded = await this.removeUserFromMobileProp(remove_from_project_ids, new_user_data);
        const update_not_succeeded = await this.addUserToMobileProp(update_project_ids, new_user_data, new_mobile_roles);

        return [remove_not_succeeded, update_not_succeeded];
        
    }

    async removeUserFromMobileProp(project_ids, new_user_data){
        const not_succeeded = {};
        await Promise.all(project_ids.map(async (project_id) => {
            const mobile_property = cloneDeep({users: this.mobile_roles[project_id]});
            mobile_property.users = mobile_property.users.filter((user) => user.email !== new_user_data.email);
        
            const result = await setProjectProperty(this.project.company, project_id, 'mobile.user_roles', mobile_property);
            if (result) {
                this.$emit('updateMobileProperty', {project_id , value: mobile_property.users});
            }else {
                not_succeeded[project_id] = {users: this.mobile_roles[project_id]};
            }
        }));
        return Object.keys(not_succeeded);
    }

    async addUserToMobileProp(project_ids, new_user_data, new_mobile_roles){
        const not_succeeded = {};
        await Promise.all(project_ids.map(async (project_id) => {
            const mobile_property = cloneDeep({users: this.mobile_roles[project_id]});
            const user_roles = mobile_property.users.find((user) => user.email === new_user_data.email);
            if (user_roles && isEqual(user_roles.role, new_mobile_roles)) {
                return;
            }
            if (user_roles) {
                user_roles.email = new_user_data.email;
                user_roles.role = new_mobile_roles;
            }else {
                mobile_property.users.push({email: new_user_data.email, role: new_mobile_roles});
            }
            const result = await setProjectProperty(this.project.company, project_id, 'mobile.user_roles', mobile_property);
            if (result) {
                this.$emit('updateMobileProperty', {project_id, value: mobile_property.users});
            }else {
                not_succeeded[project_id] = {users: this.mobile_roles[project_id]};
            }
        }));
        return Object.keys(not_succeeded);
    }

    async updateRestrictedProp(new_user_data) {
        if ((!this.user_details.dashboard_access && !new_user_data.dashboard_access &&
            isEqual(this.user_details.projects, new_user_data.projects)) || 
            (this.user_details.dashboard_access && new_user_data.dashboard_access)
        ) {
            return [[], []];
        }

        const prev_admin = this.user_details.role === 'CA' || this.user_details.role === 'CM';
        const new_admin = new_user_data.role === 'CA' || new_user_data.role === 'CM';
        const prev_project_user = this.user_details.role === 'PU' || this.user_details.role === 'PR';
        const new_project_user = new_user_data.role === 'PU' || new_user_data.role === 'PR';

        if (prev_admin && new_admin) return [[], []];

        let restricted_project_ids = [], access_project_ids = [];
        if (prev_admin && new_project_user) {
            if (!new_user_data.dashboard_access) {
                restricted_project_ids.push(...new_user_data.projects);
            }
        }else if (prev_project_user && new_admin) {
            if (!this.user_details.dashboard_access){
                access_project_ids.push(...this.user_details.projects);
            }
        }else if (!isEqual(this.user_details.projects, new_user_data.projects)){ // prev and current project user but different projects list
            if (!this.user_details.dashboard_access && !new_user_data.dashboard_access) {
                new_user_data.projects.forEach((project_id) => {
                    if (!this.user_details.projects.includes(project_id)) {
                        restricted_project_ids.push(project_id);
                    }
                });

                this.user_details.projects.forEach((project_id) => {
                    if (!new_user_data.projects.includes(project_id)) {
                        access_project_ids.push(project_id);
                    }
                });
            }else if (this.user_details.dashboard_access && !new_user_data.dashboard_access) {
                restricted_project_ids.push(...new_user_data.projects);
            }else if (!this.user_details.dashboard_access && new_user_data.dashboard_access) {
                access_project_ids.push(...new_user_data.projects);

            }
        }else if (this.user_details.dashboard_access && !new_user_data.dashboard_access){
            restricted_project_ids.push(...new_user_data.projects);
        }else if (!this.user_details.dashboard_access && new_user_data.dashboard_access) {
            access_project_ids.push(...new Set([...this.user_details.projects , ...new_user_data.projects]));
        }
        access_project_ids = access_project_ids.filter((project_id) => project_id !== 'all');
        restricted_project_ids = restricted_project_ids.filter((project_id) => project_id !== 'all');
        const access_not_succeeded = await this.removeUserFromRestrictedProp(access_project_ids, new_user_data);
        const restricted_not_succeeded = await this.addUserToRestrictedProp(restricted_project_ids, new_user_data);

        return [access_not_succeeded, restricted_not_succeeded];
    }

    async removeUserFromRestrictedProp(project_ids, new_user_data){
        const not_succeeded = {};
        await Promise.all(project_ids.map(async (project_id) => {
            const current_project = this.projects.find((project) => project.id === project_id);
            let restricted_prop = cloneDeep(current_project.restricted);
            restricted_prop = restricted_prop.filter((username) => username !== new_user_data.email);
            const result = await setProjectProperty(this.project.company, project_id, 'dashboard.Forbidden_users', restricted_prop);
            if (result) {
                this.updateRestrictedByProject({project_id, value: restricted_prop });
            }else {
                not_succeeded[project_id] = current_project.restricted;
            }
        }));
        return Object.keys(not_succeeded);
    }

    async addUserToRestrictedProp(project_ids, new_user_data){
        const not_succeeded = {};
        await Promise.all(project_ids.map(async (project_id) => {
            const current_project = this.projects.find((project) => project.id === project_id);
            if (!current_project.restricted.includes(new_user_data.email)) {
                const restricted_prop = cloneDeep(current_project.restricted);
                restricted_prop.push(new_user_data.email);
                const result = await setProjectProperty(this.project.company, project_id, 'dashboard.Forbidden_users', restricted_prop);
                if (result) {
                    this.updateRestrictedByProject({project_id, value: restricted_prop });
                }else {
                    not_succeeded[project_id] = current_project.restricted;
                }
            }
        }));

        return Object.keys(not_succeeded);
    }

    get mobile_roles_options(){
        return [
            {text: this.$t('All'), value: 'all'},
            {text: this.$t('Alerts'), value: 'alerts'},
            {text: this.$t('Bluetooth'), value: 'bt_technician'},
            {text: this.$t('Technician'), value: 'technician'},
            {text: this.$t('Installer'), value: 'installer'}
        ];
    }
}

