import { render, staticRenderFns } from "./UserDialog.vue?vue&type=template&id=bfeb22fa&scoped=true"
import script from "./UserDialog.vue?vue&type=script&lang=ts"
export * from "./UserDialog.vue?vue&type=script&lang=ts"
import style0 from "./UserDialog.vue?vue&type=style&index=0&id=bfeb22fa&prod&lang=scss&scoped=true"
import style1 from "./UserDialog.vue?vue&type=style&index=1&id=bfeb22fa&prod&lang=scss"
import style2 from "./UserDialog.vue?vue&type=style&index=2&id=bfeb22fa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfeb22fa",
  null
  
)

export default component.exports