
























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Projects } from '@/store';
import { Prop } from 'vue-property-decorator';
import { forgotPassword } from '@/modules/ApiUsers';

@Component({
    components: {}
})
export default class UserProfile extends Vue{
    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;
    @Projects.State('list') projects;

    @Prop() user;
    @Prop() project_name_by_id;
    @Prop() mobile_roles;
    @Prop() mobile_roles_options;

    getRelatedProjectsNames(){
        const projects_names = this.user.projects.map((project_id) => this.project_name_by_id[project_id]);
        return projects_names.join(', ');
    }

    // getNotificationsProjectsNames(){
    //     const projects_names = this.user.projects_with_notifications.map((project_id) => this.project_name_by_id[project_id]);
    //     return projects_names.join(', ');
    // }

    getMobileRoles(){
        return this.user.mobile.map((permission) => {
            const option = this.mobile_roles_options.find((option) => option.value === permission);
            return option && option.text || permission;
        }).join(', ');
    }

    async sendResetEmail(){
        try {
            await forgotPassword(this.user.email);
            this.throwNotify({
                type: 'success',
                title: `${this.$t('Success')!}`,
                text: this.$t('Email with password reset successfully sent')
            });
        } catch (e) {
            this.throwNotify({
                type: 'error',
                title: `${this.$t('Error')!}`,
                text: this.$t(`Couldn't send email with password reset`)
            });
        }
    }
}

